<template>
  <v-container>
    {{ items }}
  </v-container>
</template>

<script>
  import { api } from '@/api'

  export default {
    name: 'DashboardOrganizations',

    data () {
      return {
        items: [],
      }
    },

    computed: {
    },

    created () {
      this.loadData()
    },

    methods: {
      async loadData () {
        this.loading = true
        try {
          const apiResp = await api.get('organizations', {})
          this.items = apiResp.data
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load responses',
          })
        }
        this.loading = false
      },
    },
  }
</script>
